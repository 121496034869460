.container {
  height: 100%;
  position: static;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-image: url("https://pgm-media.fteledition.fr/Uploads/a8d25c5f-6df2-402b-b4c5-3b3d68180661/MicrosoftTeams-image_(19).png");
  background-repeat: no-repeat;
  background-size: 60% 100%;
  background-position: right top;
}
