@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

:root {
  --bg-image: url("https://pgm-media.fteledition.fr/Uploads/a8d25c5f-6df2-402b-b4c5-3b3d68180661/MicrosoftTeams-image_(19).png");
}
html,
body {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-image: var(--bg-image);
  background-size: cover;
  background-position: 50%;
  will-change: transform;
}
main {
  width: 100%;
}
